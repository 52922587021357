.toggleFilter {
    display: flex;
    align-items: center;
    background-color: var(--color-input-background);
    border-radius: 20px;
    overflow: hidden;
    min-height: 36px;
  }
  
  .toggleOption {
    padding: 8px 16px;
    cursor: pointer;
    
    &.selected {
        background-color: var(--color-text-on-tertiary);
        border-radius: 20px;
        transition: background-color 0.2s, color 0.2s;
    }

    .defaultColor {
        color: var(--color-text);
    }
  }
  