.content {
  display: flex;
  gap: var(--m);
  height: 100%;

  & > * {
    &:first-child {
      flex: 1 1 500px;
      overflow: hidden;
    }
    &:last-child {
      flex-basis: 38%;
    }
  }
}

.box {
  display: flex;
  flex-direction: column;
  gap: var(--m-s);
}

.tableContainer {
  overflow: hidden;
  overflow-y: scroll;
  height: 100%;
  outline: 1px solid var(--color-border);
  border-radius: var(--border-radius-2);
  > div {
    outline: none;
  }
  .tbody {
    overflow: auto;
  }
}
