.reviewContainer {
  height: 100%;
  display: flex;
  flex-direction: column;

  .tableWrapper {
    overflow: hidden;
    flex: 1;
  }

  .actions {
    margin-top: var(--m);
    display: flex;
    justify-content: space-between;
  }
}

.filters {
  width: fit-content;
  margin-bottom: var(--m-xs);
}

.spinner {
  border: 1px solid var(--color-border);
  margin-top: var(--m);
  padding: var(--m);
  border-radius: var(--border-radius-1);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.errorContainer {
  display: flex;
  justify-content: center;
}

.headerCell {
  display: flex;
  gap: 7px;
}

.cellContent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: calc(100% - 1px);
  align-items: center;
  gap: var(--m-xs);

  .tooltipWrapper {
    display: flex;
  }

  & > span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
