.ag-theme-alpine,
.ag-theme-alpine-dark {
  font-family: var(--font-family) !important;
  --ag-borders: none;
  --ag-header-height: 44px;
  --ag-header-foreground-color: var(--color-text);
  --ag-header-background-color: var(--color-input-background-soft);
  --ag-background-color: var(--color-input-disabled);
  --ag-odd-row-background-color: var(--color-input-disabled);
  --ag-header-column-separator-display: block;
  --ag-row-border-width: 0px;
  --ag-row-hover-color: var(--color-input-disabled);
  --ag-alpine-active-color: var(--color-primary);

  &.ag-theme-alpine-dark-custom-select {
    --ag-selected-row-background-color: var(--color-background);
  }
}

.ag-pinned-left-header {
  border-bottom: solid 1px var(--color-border);
  .ag-header-cell:hover {
    background-color: var(--color-background-modal) !important;
  }
}

.ag-pinned-left-header {
  border-right: solid 1px var(--color-border) !important;
}

.ag-header-viewport {
  border-bottom: solid 1px var(--color-border) !important;
}

.ag-header,
.ag-row {
  border-bottom: none !important;
}

.empty-header {
  border-right: solid 1px var(--color-border) !important;
}

.ag-header-container,
.ag-header-cell {
  background-color: var(--color-background-modal);
  font-size: var(--font-size);
}

.ag-header-cell-comp-wrapper {
  color: var(--color-text);
}

.ag-cell:not(.ag-cell-focus) {
  border-right: solid 0.5px var(--color-border) !important;
}

.ag-header-cell-resize {
  &::after {
    background-color: var(--color-border) !important;
  }
}

.ag-row-even,
.ag-row-odd {
  background-color: var(--color-background) !important;
  font-size: var(--font-size) !important;
  color: var(--color-text) !important;
}

.ag-body .ag-body-viewport,
.ag-input-field-input {
  background-color: var(--color-background) !important;

  &::selection {
    background-color: var(--color-primary);
    color: white;
  }
}

.ag-theme-alpine .ag-header,
.ag-theme-alpine-dark .ag-header {
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.ag-theme-alpine .ag-root-wrapper,
.ag-theme-alpine-dark .ag-root-wrapper {
  border: 1px solid var(--color-border);
  border-radius: 10px;
  background-color: transparent;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine-dark .ag-cell {
  border: 0.5px solid var(--color-border);
  &:first-of-type {
    border-left: none;
  }
  &:last-of-type {
    border-right: none;
  }
}
.grid-wrapper {
  height: 100%;
}
