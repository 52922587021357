[data-theme="light"] {
  color-scheme: light;

  // BACKGROUND

  --color-background: #{$gray-100};
  --color-background-main: #{$base-white};
  --color-background-modal: #{$base-white};
  --color-background-modal-hover: #{$base-white};
  --color-background-modal-veil: #0e1116;
  --color-background-modal-shadow: transparent;
  --color-background-modal-shade: #{$gray-100};

  --color-tertiary: #{$base-white};
  --color-tertiary-hover: #{$gray-100};
  --color-tertiary-focus: #{$base-white};
  --color-tertiary-disabled: #{$gray-200};

  --color-background-menu: #{$base-white};
  --color-background-menu-hover: #{$gray-100};

  // TEXT

  --color-text-strong: #{$gray-900};
  --color-text: #{$gray-800};
  --color-text-soft: #{$gray-500};

  --color-text-on-tertiary: #{$gray-700};
  --color-text-on-tertiary-disabled: #{$gray-500};

  --color-error: #{$error-200};
  --color-text-error: #{$error-500};
  --color-background-error: #{$error-500};
  --color-background-error-hover: #{$error-600};
  --color-background-error-soft: #{$error-200};

  // INPUT

  --color-input-background: #{$base-white};
  --color-input-background-soft: #{$gray-300};
  --color-input-border: #{$gray-700};
  --color-input-placeholder: #{$gray-700};
  --color-input-text-disabled: #{$gray-700};
  --color-input-disabled: #{$gray-50};

  --color-border: #{$gray-800};

  --color-background-small-button-selected: #{$gray-100};
  --color-background-small-button-hover: #{$gray-50};
  --color-text-small-button: var(--color-text);

  // BUTTON (default)

  --color-button: #{$base-white};
  --color-button-hover: #{$gray-100};
  --color-button-disabled: #{$gray-25};

  --color-button-text: var(--color-text-soft);
  --color-button-text-disabled: #{$gray-300};

  --color-button-border: #{$gray-300};

  // BORDER

  --color-border: #{$gray-300};
  --color-border-soft: #{$gray-200};

  // ICONS

  --color-icon: #{$gray-blue-900};

  // SHADOW

  --color-bisel: rgba(0, 0, 0, 0.05);

  // BRAND

  --color-tableflow-text: #130638;

  // STEPPER

  --color-stepper: #{$gray-cool-300};
  --color-stepper-active: #{$success-300};
}
