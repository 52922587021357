.container {
  height: calc(100% - var(--m) * 2);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  margin: var(--m) auto 0;
  gap: var(--m);
  flex-grow: 1;
  width: 100%;
  color: var(--color-text-soft);

  &:not(.hideBorder) {
    outline: 1px solid var(--color-border);
    border-radius: var(--border-radius-2);
    overflow: hidden;
    animation-name: color;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-out;
  }
}

@keyframes color {
  0% {
    outline-color: var(--color-text-soft);
  }
  50% {
    outline-color: var(--color-background);
  }
  100% {
    outline-color: var(--color-text-soft);
  }
}
